import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../redux/auth/authActions';
import logo from '../assets/images/logo.png';

function Restore() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [phase, setPhase] = useState(1);
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(0);
  const [resent, setResent] = useState(0);
  const [errors, setErrors] = useState('');

  const handleSubmit = async () => {
    setErrors('');

    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      setErrors(t('register.email_required'));
    } else if (!email.match(regexp)) {
      setErrors(t('register.email_error'));
    } else if (await dispatch(forgotPassword(email))) {
      setPhase(2);
    } else {
      setErrors(t('restore.not_exiting_email', { email }));
    }
  };

  useEffect(() => {
    if (phase === 2) {
      setTimer(59);
    }
  }, [phase, resent]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [timer]);

  return (
    <section className="login-starbitrex register-starbitrex">

      <div className="login-section new-login-sec">
        <div className="image-section" />
        <div className="login-data">
          <div className="abc-2">
            <div className="logo">
              <Link to="/"><img alt="Qui-x" className="img-fluid" src={logo} /></Link>
            </div>
            <form>
              <div className="password-input-field">
                {phase === 1
                  ? <p className="text-white-light">{t('labels.email')}</p>
                  : (
                    <>
                      <p className="text-white-light m-0">{t('restore.email_sent')}</p>
                      <span className="text-white-light" style={{ fontSize: '10px' }}>{t('restore.check_email')}</span>
                    </>
                  )}
                <div>
                  <input type="text" className="text-light" placeholder={`${t('login.type_email')}...`} disabled={timer === 0 ? 0 : 1} value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                {phase === 2
                  ? (
                    <div className="restore-timer">
                      <span className="text-white-light">
                        <FontAwesomeIcon className="text-white-light me-1" icon={faQuestionCircle} />
                        {t('restore.not_receive')}
                      </span>
                      <span className="text-white-light">
                        00:
                        {timer < 10 ? `0${timer}` : timer}
                        <span onClick={() => { if (timer === 0) handleSubmit(); }} className={timer === 0 ? 'text-green ms-1' : 'ms-1'} style={{ cursor: 'pointer' }}>
                          {t('labels.resend')}
                        </span>
                      </span>
                    </div>
                  )
                  : null}
              </div>
              {errors ? (
                <div style={{ color: '#FE6E00' }} className="alert alert-danger">
                  {' '}
                  {errors}
                  {' '}
                </div>
              )
                : ('')}
              <div className="text-center">
                <button 
                  type="button" 
                  className="btn enter-btn" 
                  disabled={timer === 0 ? 0 : 1}
                  onClick={() => { if (timer === 0) handleSubmit(); if (phase === 2) setResent(!resent); }}
                  style={{ textTransform: 'uppercase' }}
                >
                  {phase === 1 ? t('labels.send_email') : t('labels.resend')}
                </button>
              </div>
              <div className="my-4 text-center">
                <Link to="/login">{t('labels.login_page')}</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Restore;
